function App() {
  const requestInvite = async (event) => {
    event.preventDefault();
    const route = "/.netlify/functions/RequestInvite";
    await fetch(route, {
      method: "POST",
      body: JSON.stringify({ email: event.target.email.value }),
    });
  };
  return (
    <div>
      <h1>Side-project Standup</h1>
      <div>Every wednesday at 8pm</div>
      <form onSubmit={requestInvite}>
        <input name="email" type="email" placeholder="email" />
        <button>Register to join</button>
      </form>
    </div>
  );
}

export default App;
